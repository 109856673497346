<template>
  <tbody @click="$emit('click', index)" :class="{selected: selected, invalid: invalid}">
    <tr class="single">
      <td v-for="column in goodsLineCoTable" :key="column.label" :class="{readonly: column.readonly}" :style="{width: column.width + 'px' || 'auto', maxWidth: column.width + 'px' || 'auto',textAlign: column.align}">
        <TwOverflowTooltip v-if="column.readonly && column.key !== 'countryOfOriginName'" :content="getContent(column.key)" />
        <TwProcessGroupListEditGoodsLineItem v-else-if="column.item" :item="column.item" :selectGoodsIndex="index" :tableName="column.table" :groupName="column.group" :parentGroup="column.parentGroup" :readonly="column.readonly" :tableView="true" v-model="localValue" />
      </td>
    </tr>
  </tbody>
</template>

<script>
import _ from 'lodash';
import TwProcessGroupListEditGoodsLineItem from '@/components/molecules/TwProcessGroupListEditGoodsLineItem';
import TwOverflowTooltip from '@/components/atoms/TwOverflowTooltip';
import { formatQuantity } from '@/utils/searchUtil.js';
import { CO_SUBMIT_FLG } from 'lib-tw-common';

export default {
  name: 'TwProcessGroupListEditGoodsLineCoTable',
  props: {
    value: Object,
    index: Number,
    selected: Boolean,
    hasError: Array,
    schemas: Array,
  },
  inject: ['s'],
  components: {
    TwProcessGroupListEditGoodsLineItem,
    TwOverflowTooltip,
  },
  data() {
    return {
    };
  },
  computed: {
    localValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    goodsLineCoTable() {
      return this.getTableData(this.schemas);
    },
    invalid() {
      if (_.isEmpty(this.hasError)) {
        return false;
      }
      return _.some(this.hasError, e => {
        return e.startsWith(`linkageGoodsLineCo[${this.index}]`);
      });
    },
    getContent() {
      return key => {
        if (key === 'goodsName') {
          const goodsLine = _.find(this.localValue.linkageGoodsLine, g => {
            return g.keyGrp.goodsLineSeq === this.localValue.linkageGoodsLineCo[this.index].keyGrp.goodsLineSeq;
          });
          return _.get(goodsLine, 'goodsGrp.goodsShortName') || '';
        }
        if (key === 'quantity1') {
          const quantityGrp = this.localValue.linkageGoodsLineCo[this.index].quantityGrp;
          return formatQuantity(quantityGrp.quantity1, quantityGrp.unit1);
        }
        if (key === 'coApplicationNo') {
          return _.get(this.localValue, `linkageGoodsLineCo[${this.index}].keyGrp.coApplicationNo`) || '';
        }
      };
    },
    ignore() {
      return _.get(this.localValue, `linkageGoodsLineCo[${this.index}].certificateOfOriginGrp.coSubmitFlg`) !== CO_SUBMIT_FLG.TRUE;
    },
  },
  methods: {
    getTableData(schema) {
      const ret = _.compact(_.map(schema, column => {
        const table = _.find(this.s.schemas.tables, {variableName: column.table});
        if (!table) {
          return null;
        }
        const group = _.find(table.groups, {variableName: column.group});
        const item = _.find(group.children, {key: column.key});
        return {
          key: column.key,
          table: column.table,
          group: column.group,
          item: item,
          parentGroup: group,
          width: column.width,
          label: column.label,
          readonly: column.readonly,
        }
      }));
      return ret;
    },
  }
};
</script>

<style lang="scss" scoped>
  tbody:hover {
    background-color: $color_gray_background;

    td.goods_line_table_inner img.close_times {
      opacity: 1;
    }
  }

  tbody.selected {
    background-color: $color_selected_row;
  }

  tbody.invalid td:not(.goods_line_table_inner) {
    background-color: rgba(224, 0, 1, 0.07);
  }

  th {
    text-align: center;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    word-break: break-word;
    color: #5E5873;
    padding: 4px 5px;
    border: 1px solid $color_gray_300;
    background: $color_dark_blue;
    color: white;
    // font-weight: bold;
  }

  td {
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    white-space: nowrap;
    padding: 0;
    border: 1px solid $color_gray_300;
    border-bottom: 0;

    &.readonly {
      padding: 0 5px;
      font-size: 12px;
      background-color: rgba(237, 237, 244, 0.75);
      cursor: not-allowed;
    }

    .el-form-item {
      width: 100%;
      padding-right: 0;
      margin: 0;
      margin-bottom: 0!important;

      ::v-deep {
        label.el-form-item__label {
          display: none;
        }

        .el-input, .el-select, .el-input-number, .el-input-number.amount {
          width: 100%!important;
        }

        .el-input-number .el-input__inner {
          text-align: right;
        }

        .el-input__inner {
          font-size: 12px;
          width: 100%;
          background: transparent;
          box-shadow: none;
          border-radius: 0;
          padding: 0 5px;

          &:not(:focus) {
            border: 0;
          }
        }

        .el-input.is-disabled .el-input__inner {
          color: $color_black;
          background-color: rgba($color_gray_300, .75);
        }

        .el-select {
          .el-input__inner {
            padding-right: 20px;
            overflow: hidden;
            white-space: nowrap;
          }
          .el-input__suffix {
            right: 0;
          }
        }

        .el-form-item__error {
          white-space: normal;
          font-size: 10px;
          left: 0;
          padding: 0 5px;
          margin-bottom: 5px;
        }

        .warning_message {
          width: 120px;
          font-size: 10px;
          background-position: 5px 2px;
          padding-left: 20px;
          margin-bottom: 5px;
          white-space: normal;
        }
      }
    }
  }

  tr.single td {
    border-bottom: 1px solid $color_gray_400!important;
  }

  td.goods_line_table_inner {
    position: relative;
    border-bottom: 1px solid $color_gray_400!important;
    // border-top: 1px solid #666;

    img.close_times {
      position: absolute;
      right: 4px;
      top: 50%;
      margin-top: -10px;
      width: 20px;
      height: 20px;
      cursor: pointer;
      opacity: 0;
    }

    table  {
      table-layout: fixed;
      min-width: 100%;
      border: 0;
      border-collapse: collapse;

      th {
        border-top: 0!important;
        border-bottom: 0;
        padding: 0 5px;
        line-height: 0;
        height: 0;
        overflow: hidden;
        background: transparent;
      }

      td {
        border-top: 0!important;
        border-bottom: 0!important;
        padding: 0;
      }

      tr th:first-child, tr td:first-child {
        border-left: 0!important;
      }

      tr th:last-child, tr td:last-child {
        border-right: 0!important;
      }

      tr td:last-child {
        padding-right: 28px;
      }
    }
  }


</style>
